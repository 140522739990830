import styled from "styled-components";
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants";

export const Container = styled.div`
    background: #3a1a3c;
    padding: 2.5rem;

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        padding: 1.5rem;
    }
`;

export const ButtonContainer = styled.a`
    text-decoration: none;
    margin-left: 10rem;
    min-width: 14rem;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        width: 100%;
        margin-left: 0;
    }
    button {
        height: 3.5rem;
    }
`;

export const ContentContainer = styled.div`
    display: flex;
    align-items: center;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        flex-direction: column;
    }
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        margin-bottom: 1rem;
        width: 100%;
    }
`;
