import styled from "styled-components";
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants";

export const Container = styled.div`
    padding: 0 16rem;
    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        padding: 0 6.25rem;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        padding: 0 1.5rem;
    }
`;
