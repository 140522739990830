import React, { useMemo } from "react"
import BenefitsFlippableListSection from "@common/CommonSections/BenefitsFlippableListSection"
import { BENEFIT_LIST_SECTION_TITLE, LIST_ITEMS } from "./constants"
import { StyledBenefitsListSectionContainer } from "./CompanyCooperationPros.style"
import { TABLET_MAX_WIDTH } from "../../../../constants"

const CompanyCooperationPros = () => {
  const isDesktop = useMemo(() => {
    const { innerWidth: width, innerHeight: height } = window
    return width > TABLET_MAX_WIDTH
  }, [])

  return (
    <StyledBenefitsListSectionContainer>
      <BenefitsFlippableListSection
        configuration={LIST_ITEMS}
        heading={BENEFIT_LIST_SECTION_TITLE}
        customIsDestop={isDesktop}
      />
    </StyledBenefitsListSectionContainer>
  )
}

export default CompanyCooperationPros
