import React from "react";
import { graphql } from "gatsby";
import Layout from "@common/Layout/Layout";
import SEO from "../components/Common/SEO/SEO";
import Landing from "../components/Landing/Landing";

const LandingTemplate = ({ path, data }) => {
    const { strapiJobDescriptions: jobDescription } = data;

    return (
        <>
            <SEO
                title={jobDescription.metaTitle}
                titleTemplate="Impressit"
                description={jobDescription.metaDescription}
                path={path}
                isNotIndexed={data.strapiJobDescriptions.noindex}
                ownMeta={{
                    name: "googlebot",
                    content: data.strapiJobDescriptions.noindex
                        ? "noindex, nofollow"
                        : "all",
                }}
            />
            <Layout isNoPadding>
                <Landing
                    content={jobDescription.content}
                    headerButtonNavigation={
                        jobDescription.headerButtonNavigation
                    }
                    allStrapiCaseStudies={data.allStrapiCaseStudies}
                    headerMainImage={jobDescription.headerMainImage[0].url}
                    headerTitle={jobDescription.headerTitle}
                    headerDescription={jobDescription.headerDescription}
                    headerButtonText={jobDescription.headerButtonText}
                />
            </Layout>
        </>
    );
};

export default LandingTemplate;

export const query = graphql`
    query JobDescriptionsQuery($id: String) {
        allStrapiCaseStudies {
            nodes {
                id
                logo {
                    url
                }
                wideImage {
                    url
                }
                metaUrl
                title
            }
        }
        strapiJobDescriptions(id: { eq: $id }) {
            id
            noindex
            metaTitle
            metaDescription
            metaUrl
            headerTitle
            headerDescription
            headerButtonText
            headerButtonNavigation
            content
            headerMainImage {
                url
            }
        }
    }
`;
