import React, { useEffect } from "react";
import loadable from "@loadable/component";
import { GLOBAL_APPLICATION_INTERNAL_LINKS } from "@links";
import SearchForSections from "./utils/SearchForSections";
import PageHeader from "./components/PageHeader/PageHeader";
import { Container } from "./Landing.style";
import { 
    SECOND_CATCH_UP_SECTION_TITLE,
    SECOND_CATCH_UP_SECTION_DESCRIPTION
} from "./constants";

const CrawlableSecondCatchUpSection = loadable(() =>
    import("@common/CommonSections/CrawlableSecondCatchUpSection")
);

const LinkButton = loadable(() => import("@common/LinkButton"));

const Landing = ({
    content,
    headerButtonNavigation,
    headerTitle,
    headerDescription,
    headerButtonText,
    headerMainImage,
    allStrapiCaseStudies,
}) => {
    useEffect(() => {
        if (document) {
            const el = document.getElementById("show-btn");
            if (el) {
                el.addEventListener("click", () => {
                    const containerShowFourItems =
                        document.getElementById("show-four-items");
                    const containerShowAll =
                        document.getElementById("show-all");
                    if (containerShowAll) {
                        el.textContent = "Show";
                        containerShowAll.id = "show-four-items";
                    }
                    if (containerShowFourItems) {
                        el.textContent = "Less";
                        containerShowFourItems.id = "show-all";
                    }
                });
            }
            const listItems = document.querySelectorAll('[name="faq"]');
            if (listItems) {
                listItems.forEach(item => {
                    item.addEventListener("click", () => {
                        const isIdSet = item.getAttribute("id") === "faq-show";
                        if (isIdSet) {
                            item.removeAttribute("id");
                        } else {
                            item.setAttribute("id", "faq-show");
                        }
                    });
                });
            }
        }
    }, [document]);
    return (
        <>
            <PageHeader
                headerButtonNavigation={headerButtonNavigation}
                headerDescription={headerDescription}
                headerTitle={headerTitle}
                headerButtonText={headerButtonText}
                headerMainImage={headerMainImage}
            />
            <Container>
                <div
                    dangerouslySetInnerHTML={{
                        __html: SearchForSections.findSectionsInMarkup(
                            content,
                            allStrapiCaseStudies
                        ),
                    }}
                />
                <CrawlableSecondCatchUpSection
                    isAnimated
                    title={SECOND_CATCH_UP_SECTION_TITLE}
                    description={SECOND_CATCH_UP_SECTION_DESCRIPTION}
                >
                    <LinkButton to={GLOBAL_APPLICATION_INTERNAL_LINKS.CONTACT_US}>
                        Contact Us
                    </LinkButton>
                </CrawlableSecondCatchUpSection>
            </Container>
        </>
    );
};

export default Landing;
