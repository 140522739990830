import React from "react";
import { TextContainer } from "./Heading.style";
import { Description, Title } from "../CommonStyles/CommonStyles.style";

export const Heading = ({ title, description }) => (
    <TextContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
    </TextContainer>
);
