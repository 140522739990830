import styled from "styled-components"
import { MOBILE_MIN_WIDTH } from "@constants"

export const StyledBenefitsListSectionContainer = styled.div`
  & .benefits-card {
    margin-bottom: 2.5rem;
    height: 19rem;

    &__icon {
      svg {
        width: 5rem;
        height: 5rem;
      }
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
      height: fit-content;
    }
  }

  & .react-card-flip {
    &:hover {
      & .react-card-front {
        transform: rotateX(180deg) !important;
      }
      & .react-card-back {
        transform: rotateX(0deg) !important;
      }
    }
  }
`;
