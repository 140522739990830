import React from "react";
import * as ReactDOMServer from "react-dom/server";
import FeedbacksSection from "@common/SlideShow/ContentWithFeedback/FeedbacksSection/FeedbacksSection";
import he from "he";
import CaseStudiesSection from "../components/CasesStudySection/CasesStudySection";
import PropositionSection from "../components/PropositionSection/PropositionSection";
import CompanyCooperationPros from "../components/CompanyCooperationPros/CompanyCooperationPros";
import OfferSectionWrapper from "../components/OfferSectionWrapper/OfferSectionWrapper";
import CallToActionSection from "../components/CallToAction/CallToActionSection";
import MainOffersPresentationSection from "../components/MainOffersPresentationSection/MainOffersPresentationSection";
import MainOffersPresentationSectionWithImage from "../components/MainOffersPresentationSectionWithImages/MainOffersPresentationSectionWithImages";
import ProudSectionWrapper from "../components/ProudSectionWrapper/ProudSectionWrapper";
import FAQSection from "../components/FAQSection/FAQSection";
import { Heading } from "../components/Heading/Heading";

export default class SearchForSections {
    static findSectionsInMarkup(text, allStrapiCaseStudies) {
        const decodedMarkup = he.decode(text);
        const sectionRegex = {
            proposition: /<PropositionSection\s+([^>]*)\s*\/?>/g,
            companyCooperationPros: /<CompanyCooperationPros\s+([^>]*)\s*\/?>/g,
            callToAction: /<CallToActionSection\s+([^>]*)\s*\/?>/g,
            casesStudy: /<CaseStudiesSection\s+([^>]*)\s*\/?>/g,
            proud: /<ProudSection\/?>/g,
            feedback:
                /<FeedbackSection(?:\s+[^>]*?)?\s+feedbacks="(.*?)"(?:\s+[^>]*?)\s*\/?>/g,
            offer: /<OfferSection\/?>/g,
            mainOffersPresentation:
                /<MainOffersPresentationSection\s+([^>]*)\s*\/?>/g,
            mainOffersPresentationWithImage:
                /<MainOffersPresentationSectionWithImage\s+([^>]*)\s*\/?>/g,
            FAQ: /<FAQSection\s+([^>]*)\s*\/?>/g,
            heading: /<Heading\s+([^>]*)\s*\/?>/g,
        };
        let match;
        let newText = decodedMarkup;
        // finds <Example title="title"/>
        const globalPropsRegex = /(\w+)="(.*?)"/g;
        function replaceSectionWithTitleMatches(
            newText,
            regexPattern,
            component
        ) {
            let match;
            let renderComponentString = newText;
            while (
                (match = regexPattern.exec(renderComponentString)) !== null
            ) {
                const props = {};
                match[1].replace(globalPropsRegex, (match, key, value) => {
                    props[key] = value;
                });
                const componentWithProps = React.createElement(component, {
                    ...props,
                });
                const renderedComponent =
                    ReactDOMServer.renderToString(componentWithProps);
                renderComponentString = renderComponentString.replace(
                    match[0],
                    renderedComponent
                );
            }
            return renderComponentString;
        }
        function renderSectionWithoutProps(newText, regex, component) {
            let match;
            let renderComponentString = newText;
            while ((match = regex.exec(renderComponentString)) !== null) {
                const createdComponent = React.createElement(component);
                const renderedComponent =
                    ReactDOMServer.renderToString(createdComponent);
                renderComponentString = renderComponentString.replace(
                    match[0],
                    renderedComponent
                );
            }
            return renderComponentString;
        }
        function replaceSectionWithSeveralProps(
            newText,
            regex,
            component,
            propsKey
        ) {
            let match;
            let renderComponentString = newText;
            while ((match = regex.exec(renderComponentString)) !== null) {
                const props = {};
                match[0].replace(globalPropsRegex, (match, key, value) => {
                    props[key] = value;
                });
                const fixedStr = props[propsKey]
                    // finds quotes which strapi adds
                    .replace(/[\u201C\u201D]/g, '"');
                // Replaces any right curly quotes with straight quotes
                const items = JSON.parse(fixedStr);
                props[propsKey] = items;
                const section = React.createElement(component, props);
                const sectionMarkup = ReactDOMServer.renderToString(section);
                renderComponentString = renderComponentString.replace(
                    match[0],
                    sectionMarkup
                );
            }
            return renderComponentString;
        }
        function replaceCaseStudySection(
            newText,
            sectionRegex,
            component,
            passedProps
        ) {
            let renderComponentString = newText;
            while ((match = sectionRegex.exec(newText)) !== null) {
                const props = {};
                match[1].replace(globalPropsRegex, (match, key, value) => {
                    // we receive cases like a string
                    // f.e. "apify, gnetwork"
                    props[key] = value.includes(",")
                        ? value.split(",").join(",")
                        : [value];
                });
                const componentSection = React.createElement(component, {
                    ...props,
                    ...passedProps,
                });
                const componentHTML =
                    ReactDOMServer.renderToString(componentSection);
                renderComponentString = renderComponentString.replace(
                    match[0],
                    componentHTML
                );
            }
            return renderComponentString;
        }

        newText = renderSectionWithoutProps(
            newText,
            sectionRegex.offer,
            OfferSectionWrapper
        );
        newText = renderSectionWithoutProps(
            newText,
            sectionRegex.proud,
            ProudSectionWrapper
        );
        newText = replaceSectionWithTitleMatches(
            newText,
            sectionRegex.proposition,
            PropositionSection
        );
        newText = replaceSectionWithTitleMatches(
            newText,
            sectionRegex.heading,
            Heading
        );
        newText = replaceSectionWithTitleMatches(
            newText,
            sectionRegex.callToAction,
            CallToActionSection
        );
        newText = renderSectionWithoutProps(
            newText,
            sectionRegex.companyCooperationPros,
            CompanyCooperationPros
        );
        newText = replaceSectionWithSeveralProps(
            newText,
            sectionRegex.feedback,
            FeedbacksSection,
            "feedbacks"
        );
        newText = replaceSectionWithSeveralProps(
            newText,
            sectionRegex.mainOffersPresentation,
            MainOffersPresentationSection,
            "offers"
        );
        newText = replaceSectionWithSeveralProps(
            newText,
            sectionRegex.mainOffersPresentationWithImage,
            MainOffersPresentationSectionWithImage,
            "offers"
        );
        newText = replaceSectionWithSeveralProps(
            newText,
            sectionRegex.FAQ,
            FAQSection,
            "items"
        );
        newText = replaceCaseStudySection(
            newText,
            sectionRegex.casesStudy,
            CaseStudiesSection,
            allStrapiCaseStudies
        );
        return newText;
    }
}
